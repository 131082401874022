import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import ContactFormFr from '../components/contact-us/ContactFormFr';

const ContactPage = () => (
  <Layout>
    <SEO
      title="Nous contacter - Cinélangues"
      description="Cinélangues poursuit, depuis de nombreuses années maintenant, toujours avec autant de passion et de conviction, son action de diffusion des cinématographies."
      pathname="/a-propos-nous-contacter/"
    />
    <div className="page">
      <div className="container content">
        <div className="row">
          <div className="card col-lg-5 card-illustration-4 d-sm-none d-md-block"></div>
          <div className="card col-lg-7 p-md-4">
            <div className="card-body text-bold">
              <h3>N'hésitez pas à nous contacter !</h3>
              <br />
              <ContactFormFr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactPage;
