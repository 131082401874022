import React, { Component } from 'react';

export class ContactForm extends Component {
  render() {
    return (
      <form
        method="post"
        name="contact-fr"
        action="/success"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact-fr" />
        <div className="form-group">
          <label>Vous êtes :</label>
          <select className="form-control" name="type" id="type">
            <option>Un professeur de langue</option>
            <option>Un établissement scolaire </option>
            <option>Un distributeur</option>
            <option>Un cinéma</option>
            <option>Autre</option>
          </select>
        </div>
        <div className="form-group">
          <label>Vous souhaitez :</label>
          <select className="form-control" name="souhait" id="souhait">
            <option>Donner votre avis sur la programmation</option>
            <option>Rendre compte d'un travail pédagogique réalisé en classe</option>
            <option>Nous proposer un film étranger pour la programmation</option>
            <option>
              Proposer un accompagnement pédagogique lors d'une scéance
            </option>
            <option>
              Nous proposer des ressources (cinématographique, linguistique)
            </option>
            <option>Ecrire une critique de film</option>
            <option>Bénéficier de l’offre du dispositif </option>
            <option>Candidater pour être un membre actif du dispositif</option>
            <option>Proposer un film de votre catalogue</option>
            <option>Accueillir le dispositif dans votre cinéma</option>
            <option>Autre</option>
          </select>
        </div>
        <div className="form-group">
          <div className="form-row">
            <div className="col">
              <label>Nom, Prénom :</label>
              <input
                className="form-control"
                type="text"
                required
                name="nom"
                id="name"
                placeholder="Indiquez votre nom et prénom ..."
              />
            </div>
            <div className="col">
              <label>Structure :</label>
              <input
                className="form-control"
                type="text"
                name="structure"
                id="structure"
                placeholder="Indiquez votre structure ..."
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Email :</label>
          <input
            className="form-control"
            type="email"
            required
            name="email"
            id="email"
            placeholder="Indiquez votre email ..."
          />
        </div>
        <div className="form-group">
          <label>Message :</label>
          <textarea
            className="form-control"
            name="message"
            required
            id="message"
            rows="5"
            placeholder="Ecrivez votre message ..."
          />
        </div>
        <input
          className="btn btn-primary btn-lg mt-1 mr-1"
          type="reset"
          value="Effacer"
        />
      <button className="btn btn-warning btn-lg mt-1" type="submit">
          Envoyer
        </button>
      </form>
    );
  }
}

export default ContactForm;
